Input,
Select {
    width: 16%;
    padding: 6px;
    border: 1px solid lightgrey;
    border-radius: 4px;
}

Button {
    width: 12%;
    border: none;
    background-color: #C87945;
    color: white;
    border-radius: 4px;
}