.containerInputs {
	display: flex;
	gap: 30px;
	margin-top: 10px;
}

.list {
	margin: 20px 0;
}

.list Table {
}

.popup-overlay {
	background: rgba(0, 0, 0, 0.3);
}

.popup-content {
	width: 35%;
	background: white;
	padding: 10px 30px 50px;
	border-radius: 10px;
}
